import React from 'react';
import AceEditor from 'react-ace';
import {
  Form,
  InputNumber,
  Switch,
  Input,
  Select,
  TimePicker,
  DatePicker, Row, Col
} from 'antd';
import {
  FormInput,
  FormDynamicInput,
  FormCheckbox,
  FormSelect,
} from '../../atoms';
const ShowInputs = (inputs, formItemLayout, buttonItemLayout) => {
  return (
    inputs.inputs.map(
      ({
        type,
        name,
        label,
        rules,
        placeholder,
        prefix,
        options,
        value,
        indexAsValue,
        mode,
        defaultValue,
        children,
        col,
        showSearch,
        format

      }, i) => {
        switch (type) {
          case 'checkbox':
            return (
              <FormCheckbox
                name={name}
                label={label}
                rules={rules}
                options={options}
                key={name}
                style={{ width: '100%' }}
              />
            );
          case 'dynamic-input':
            return (
              <FormDynamicInput
                name={name}
                label={label}
                rules={rules}
                placeholder={placeholder}
                formItemLayout={formItemLayout}
                buttonItemLayout={buttonItemLayout}
                key={name}
                style={{ width: '100%' }}
              />
            );
          case 'switch':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                valuePropName="checked"
                key={name}
                style={{ width: '100%' }}
              >
                <Switch checkedChildren={children.checked} unCheckedChildren={children.unchecked} />
              </Form.Item>
            );
          case 'number':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'float':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <InputNumber style={{ width: '100%' }} step='0.01' />
              </Form.Item>
            );
          case 'double':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <InputNumber style={{ width: '100%' }} step='0.00001' />
              </Form.Item>
            );
          case 'select':
            return (
              col ? (<Col span={8} key={i}>
                <FormSelect
                  name={name}
                  label={label}
                  rules={rules}
                  options={options}
                  placeholder={placeholder}
                  indexAsValue={indexAsValue}
                  mode={mode}
                  key={name}
                  initialValue={defaultValue}
                  showSearch={showSearch}

                />
              </Col>) : (<FormSelect
                name={name}
                label={label}
                rules={rules}
                options={options}
                placeholder={placeholder}
                indexAsValue={indexAsValue}
                mode={mode}
                key={name}
                initialValue={defaultValue}
              />)
            );
          case 'timepicker':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <TimePicker format="hh:mm A" use12Hours style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'rangepicker':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <DatePicker.RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'datepicker':
            var defaultFormat = "YYYY-MM-DD";
            // var defaultFormat = "DD/MM/YYYY";
            if ({ format }) {
              defaultFormat = format;
            }
            debugger;
            return (
              col ? (<Col span={8} key={i}>
                <Form.Item
                  name={name}
                  label={label}
                  rules={rules}
                  validateTrigger={['onChange', 'onBlur']}
                  key={name}
                >
                  <DatePicker format={defaultFormat} style={{ width: '100%' }} defaultValue={defaultValue} />
                </Form.Item>
              </Col>) : (<Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} defaultValue={defaultValue} />
              </Form.Item>)
            );
          case 'datetimepicker':
            return (

              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'dynamic-tag':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <Select placeholder={placeholder} mode="tags" style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'view':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                initialValue={value}
                validateTrigger={['onChange', 'onBlur']}
                key={name}
              >
                <Input disabled={true} value={placeholder} mode="tags" style={{ width: '100%' }} />
              </Form.Item>
            );
          case 'editor':
            return (
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                validateTrigger={['onSubmit']}
                key={name}
              >
                <AceEditor
                  mode="json"
                  theme="github"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    width: '100%',
                    height: 300,
                    margin: 0,
                    fontFamily: 'Fira Mono, Courier New',
                    fontSize: 13,
                    lineHeight: 1.5,
                  }}
                />
              </Form.Item>
            );
          default:
            return (
              col ? (<Col span={8} key={i}>
                <FormInput
                  type={type}
                  name={name}
                  label={label}
                  rules={rules}
                  placeholder={placeholder}
                  prefix={prefix}
                  key={name}
                  initialValue={defaultValue}
                  style={{ width: '100%' }}
                />
              </Col>) : (<FormInput
                type={type}
                name={name}
                label={label}
                rules={rules}
                placeholder={placeholder}
                prefix={prefix}
                key={name}
                initialValue={defaultValue}
                style={{ width: '100%' }}
              />)
            );
        }
      },
    ))
}

function SimpleForm({
  form,
  inputs = [],
  layout = 'horizontal',
  horizontal = { label: 8, input: 16 },
  style = {},
  initialValues = {},
  row = false,
}) {
  const formItemLayout =
    layout === 'horizontal'
      ? {
        labelCol: { span: horizontal.label },
        wrapperCol: { span: horizontal.input },
      }
      : {};

  const buttonItemLayout =
    layout === 'horizontal'
      ? {
        wrapperCol: { span: horizontal.input, offset: horizontal.label },
      }
      : {};

  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      layout={layout}
      form={form}
      style={style}
      initialValues={initialValues}
    >
      {row ? (
        <Row gutter={16}>
          <ShowInputs inputs={inputs} formItemLayout={formItemLayout} buttonItemLayout={buttonItemLayout} />
        </Row>
      ) : (
        <ShowInputs inputs={inputs} formItemLayout={formItemLayout} buttonItemLayout={buttonItemLayout} />
      )}
    </Form >
  );
}

export default SimpleForm;