var jwt = require('jsonwebtoken');
const validateJwt = () => {
    var returnRes = true;
    try {
        var decoded = jwt.decode(localStorage.getItem('isLoggedIn'));

        if (decoded) {
            returnRes = decoded;
            if (decoded['exp'] <= Date.now() / 1000) {
                returnRes = false;
            }
        }
        else {
            returnRes = false;
        }

    } catch (err) {
        // console.log(err);
        returnRes = false;
        // err
    }
    if (returnRes == false) {
        localStorage.clear();
    }
    return returnRes;
};
export { validateJwt };