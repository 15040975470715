import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import moment from "moment";
import { EditableTable, SimpleForm } from '../../../molecules';

function AddAdhocTxnItem({ isVisible, onComplete, warehouseId, type, ticketNumber, warehouseProductList = [] }) {
    if (isVisible) {
    }
    const [form] = Form.useForm();
    const [visible, setIsModalVisible] = useState(isVisible);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setIsModalVisible(isVisible);
    }, [isVisible])

    const checkValue = (_, value) => {
        if (typeof value == "number") {
            return Promise.resolve();
        }
        return Promise.reject(new Error('value must be integer'));
    };

    const checkItem = (_, value) => {
        for (var i = 0; i < warehouseProductList.length; i++) {
            if (warehouseProductList[i]['item_code'] == value) {
                form.setFieldsValue({
                    sku: warehouseProductList[i]['super8_name'],
                });
                return Promise.resolve();
                break;
            }
        }
    };

    const columns = [{
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: '100px',

    },
    {
        title: 'item_code',
        dataIndex: 'item_code',
        key: 'item_code',
        width: '100px',

    },
    {
        title: 'qty',
        dataIndex: 'qty',
        key: 'qty',
        width: '100px',
        type: "number"
    },
    {
        title: 'rate',
        dataIndex: 'rate',
        key: 'rate',
        width: '100px',
        type: "number"
    }, {
        title: 'value',
        dataIndex: 'value',
        key: 'value',
        width: '100px',
    },
    {
        title: 'upc',
        dataIndex: 'upc',
        key: 'upc',
        width: '100px',
    }, {
        title: 'warehouse_id',
        dataIndex: 'warehouse_id',
        key: 'warehouse_id',
        width: '100px',
    }, {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        width: '100px',
    }, {
        title: 'expired_at',
        dataIndex: 'expired_at',
        key: 'expired_at',
        width: '100px',
    }, {
        title: 'expired_month',
        dataIndex: 'expired_month',
        key: 'expired_month',
        width: '100px',
    }, {
        title: 'ticket_number',
        dataIndex: 'ticket_number',
        key: 'ticket_number',
        width: '100px',
    },
    ];

    const inputs = [
        {
            name: 'sku',
            label: 'sku',
            hasFeedback: true,
            type: "view"
        },
        {
            name: 'item_code',
            label: 'Item Code',
            hasFeedback: true,
            showSearch: true,
            options: warehouseProductList,
            type: 'select',
            rules:
                [
                    { validator: checkItem, }
                ]
        },
        {
            name: 'qty',
            label: 'Qty',
            hasFeedback: true,
            type: 'number',
            rules:
                [
                    { validator: checkValue, }
                ],
        },
        {
            name: 'expired_at',
            label: 'Expired At',
            hasFeedback: true,
            type: "datepicker",
            format: "DD-MM-YYYY"
        }
    ];

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const processCreateLocation = (locationValue) => {
        var item = locationValue['item'];
        var key = tableData.length + 1;
        var tableRow = {
            "key": key,
            "name": item['super8_name'],
            "item_code": locationValue['item_code'],
            "qty": locationValue['qty'],
            "rate": item['retailer_price'],
            "upc": item['barcode2'],
            "warehouse_id": locationValue['warehouse_id'],
            "type": locationValue['type'],
            "value": parseFloat(locationValue['qty']) * parseFloat(item['retailer_price']),
            "expired_at": locationValue['expired_at'],
            "expired_month": locationValue['expired_month'],
            "ticket_number":ticketNumber,
        };
        setTableData(tableData => [...tableData, tableRow]);
    };

    const handleSubmit = async () => {
        const { meta, ...values } = await form.validateFields();
        var addValues = {};
        addValues['expired_month'] = "";
        addValues['expired_at'] = "";
        for (var value in values) {
            if (value == "expired_at" && values[value] == undefined) {

            } else {
                addValues[value] = values[value];
            }
            if (values[value] instanceof moment) {
                addValues[value] = values[value].format("DD/MM/YYYY");
                addValues['expired_month'] = values[value].format("MMM");
            }
        }
        var item;
        for (var i = 0; i < warehouseProductList.length; i++) {
            if (warehouseProductList[i]['value'] == addValues['item_code']) {
                item = warehouseProductList[i];
                break;
            }
        }
        addValues['warehouse_id'] = warehouseId;
        addValues['type'] = type;
        addValues["item"] = item;
        form.resetFields();
        processCreateLocation(addValues);

    };
    const handleUpload = async () => {
        setIsModalVisible(false);
        onComplete(tableData);
    }

    return (
        <div>
            <Modal title="Add item"
                visible={visible}
                onOk={handleOk}
                closable={false}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}>

                <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                    <EditableTable
                        rowKey="shipment_id"
                        rowClassName={() => 'editable-row'}
                        columns={columns}
                        dataSource={tableData}
                        pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 4,
                            defaultCurrent: 1,
                        }}
                        size="large"
                        scroll={{ x: '2000px' }}
                    />
                </div>
                <Card>
                    <SimpleForm form={form} layout="vertical" inputs={inputs} />
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                        icon={<SaveOutlined />}
                        style={{ marginTop: 10 }}
                    >
                        Save
                    </Button>
                </Card>
                <Button
                    type="success"
                    onClick={handleUpload}
                    icon={<SaveOutlined />}
                    style={{ marginTop: 10 }}
                >
                    upload
                </Button>
            </Modal>
        </div>)
}

export default AddAdhocTxnItem;

