import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import logo from '../../../logo.svg';
import './NotFound.css';
import { proxyPath } from '../../../config'
import { Result, Button } from 'antd';

function NotFound() {
  const match = useRouteMatch();
  // console.log(match.params.id  );
  return (
    <div className="App">
      <header className="App-header">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <a
              className="App-link"
              href="/"
              rel="noopener noreferrer"
            >
              Go Home
        </a>
            // <Button type="primary">Back Home</Button>
          }
        />
      </header>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       <code>ERROR 404: </code>Page Not Found
    //     </p>
    //     <a
    //       className="App-link"
    //       href="/"
    //       rel="noopener noreferrer"
    //     >
    //       Go Home
    //     </a>
    //   </header>
    // </div>
  );
}

export default NotFound;

