import React from 'react';
import { Layout } from 'antd';

const { Content, Footer } = Layout;

function Center({ children }) {
  return (
    <Layout id="center-template">
      <Content className="cen-content">{children}</Content>
      <Footer className="app-footer">Supply Chain © GrowSari 2021</Footer>
    </Layout>
  );
}

export default Center;
