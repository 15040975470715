import React, { useCallback } from 'react';
import { Row, Col, Button, Card, Form, Typography } from 'antd';
import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import axios from '../../../utils/custom-axios';

import { FormInput } from '../../atoms';
import Center from '../../templates/Center';
import growsariLogo from '../../../assets/Growsari-Logo-Main.png';
import { API_URI } from '../../../config';
const { Title } = Typography;

const LoginForm = () => {
  const [loginForm] = Form.useForm();
  let loading = false;
  const login = useCallback((username, password) => {
    loading = true;
    const apiUrl = `${API_URI}/admin/v1/login`;
    axios.post(apiUrl, { "username": username, "password": password }, { headers: { 'Content-type': 'application/json; charset=utf-8' } }
      , { withCredentials: false })
      .then((response) => {
        console.log(response);
        alert("okay");
        localStorage.setItem('isLoggedIn', response.data.data.is_logged_in);
        localStorage.setItem('authType', response.data.data.auth_type);
        localStorage.setItem('jwtToken', response.data.data.jwt_token);
        window.location.reload();
      })
      .catch((error) => {
        alert("Error occured ");
        
      });
  }, []);

  const handleLogin = async () => {
    const { username, password } = await loginForm.validateFields();
    login(username, password);
  };

  return (
    <Form form={loginForm} onFinish={(values) => handleLogin(values)}>
      <FormInput
        name="username"
        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      />
      <FormInput
        name="password"
        type="password"
        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      />
      <Form.Item style={{ marginBottom: '5%' }}>
        <Button
          type="primary"
          loading={loading}
          onClick={handleLogin}
          icon={<LoginOutlined />}
          style={{ fontWeight: 'bold' }}
          block
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

function Login() {
  return (
    <Center>
      <Row
        className="cen-login"
        type="flex"
        justify="space-around"
        align="middle"
      >
        <Col className="app-center">
          <Card>
            <img src={growsariLogo} alt="growsari" />
            <Title level={4}>Supply Chain</Title>
            <LoginForm />
          </Card>
        </Col>
      </Row>
    </Center>
  );
}

export default Login;
