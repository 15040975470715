import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { validateJwt } from '../utils/validateJwt';
import { proxyPath } from '../config';
var isLoggedin = !!localStorage.getItem('isLoggedIn');

const authType = localStorage.getItem('authType');

function PrivateRoute({ component: RouteComponent, ...rest }) {

  if (authType) {

    isLoggedin = true;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedin && (authType == "SUPPLY_CHAIN") ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function MerchantRoute({ component: RouteComponent, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedin && (authType == "FINANCE" || authType == "OPS_FINANCE") ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function GuestRoute({ component: RouteComponent, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedin && (authType == "FINANCE" || authType == "OPS_FINANCE")) {
          return (<Redirect
            to={{
              pathname: '/approver/adhoc_transactions',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "SUPPLY_CHAIN")) {
          return (<Redirect
            to={{
              pathname: '/admin/adhoc_txns',
              state: { from: props.location }
            }}
          />)
        }
        else {
          return (<RouteComponent {...props} />)
        }
      }}
    />
  );
}
export { PrivateRoute, GuestRoute, MerchantRoute };