import React, { useState, useEffect, useCallback } from 'react';


import { Button, Divider, Modal } from 'antd';

import { Upload, message, Space, Progress } from 'antd';

import { API_URI } from '../../../../config';
import { EditableTable, SimpleForm } from '../../../molecules';
import sendNotification from '../../../../utils/sendNotification';
import { SAMPLE_CSV_URL } from '../../../../config';
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
function BulkUpload() {
    const [progress, setProgress] = useState(0);

    const [fileName, setFileName] = useState("");
    const [isOpen, setOpen] = useState(false);

    let authorizationToken = localStorage.getItem("jwtToken");
    const { Dragger } = Upload;
    const props = {
        name: 'fileupload',
        action: `${API_URI}/admin/v1/mannual_upload`,
        headers: {
            authorization: authorizationToken,
        },
        onChange: async (info) => {
            ;
            const { status } = info.file;
            ;
            await setProgress(20);
            await sleep(100);

            // await setProgress(40);
            ;

            if (status !== 'uploading') {
                if (info.file.type != "text/csv") {

                    sendNotification("File format is invalid", "Unable to read the file. Please make sure it follows the proper format.", "exit_error")
                    await setProgress(0);

                    return
                }
                ;

                await setProgress(50);

            }
            if (status === 'done') {
                for (var i = 50; i <= 100; i = i + 10) {
                    await setProgress(i);

                    await sleep(100);
                }
                setFileName(info.file.name);
                message.success(`${info.file.name} file uploaded successfully.`);

                postSuccess(info.file.response);
            } else if (status === 'error') {

                await setProgress(0);
                message.error(`${info.file.name} file upload failed.`);
            }
            else {

                await setProgress(0);
            }
        },
        onDrop(e) {

            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    function postSuccess(res) {
        console.log(res);
        var dataSource = res['data'];
    }

    return (
        <>
            <Modal open={isOpen} closable={true} onCancel={() => setOpen(false)} onOk={() => setOpen(false)}>
                <div style={{ padding: "30px" }}>
                    <Dragger {...props}>
                        <p className="ant-upload-text" style={{ color: "#6E7191;!important" }}>Drag and Drop file here</p>
                        <Space></Space>
                        <p>  </p>
                        <Space></Space>
                        <br></br>

                        <Progress percent={progress}></Progress>
                        <Divider />
                    </Dragger>
                    Here is a sample <a href={SAMPLE_CSV_URL}>sample</a>
                </div>
            </Modal>
            <Button type="primary" onClick={() => setOpen(true)}>Bulk Upload</Button>
        </>
    );
}

export default BulkUpload;