import React from 'react';
import { Form, Select } from 'antd';

function FormCheckbox({
  name,
  label,
  rules,
  placeholder,
  options,
  indexAsValue,
  mode,
  initialValue = "",
  showSearch = true
}) {

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={['onChange', 'onBlur']}
      initialValue={initialValue}
    >
      <Select placeholder={placeholder} mode={mode} showSearch={showSearch} optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {options.map((option, index) => {

          let value;
          let label;
          if (indexAsValue) {

            value = index;
            label = index;
          } else if (typeof option == "object") {

            value = option['value']
            label = option['label']
          }
          else {

            value =
              typeof option === 'string' ? option : Object.keys(option)[0];
            label = value;
          }
          // const text =
          //   typeof option === 'string' ? option : Object.values(option)[0];
          return (
            <Select.Option value={value} key={value}>
              {label}
            </Select.Option>
          );
        })}

      </Select>
    </Form.Item>
  );
}

export default FormCheckbox;
