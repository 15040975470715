
import React from 'react';
// import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
// import apolloClient from './apolloClient';
import { PrivateRoute, GuestRoute, MerchantRoute } from './lib/routes';
import {
  Login,
  AdminAdhocTxns, 
  ApproverAdhocTxnList,
  NotFound
} from './components/pages';
import moment from 'moment';
import 'jsonminify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

function App() {
  return (


    // <ApolloProvider client={apolloClient}>
      <HashRouter>
        <LastLocationProvider>
          <Switch>

            <GuestRoute exact path="/login/" component={Login} />
            {/* Admin Routes */}
            <PrivateRoute exact path="/admin/" component={AdminAdhocTxns} />
            <PrivateRoute exact path="/admin/adhoc_txns/" component={AdminAdhocTxns} />
            {/* Admin Routes dropdown structure and routes */}
            {/* Merchant Routes */}
            <MerchantRoute exact path="/approver/adhoc_transactions" component={ApproverAdhocTxnList} />
            
            <Redirect exact path="/" to="/login" />
            <Route component={NotFound} />
          </Switch>
        </LastLocationProvider>
      </HashRouter>
    // </ApolloProvider >
  );
}

export default App;
