import React from 'react';
import {
  IdcardTwoTone,
  AppstoreOutlined,
  GiftOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  FullscreenOutlined,
  ScheduleOutlined,
  ProfileOutlined,
  BranchesOutlined,
  ShoppingCartOutlined,
  ClusterOutlined,
  DragOutlined
} from '@ant-design/icons';
import { proxyPath } from './config';

const approverNavList = [
  {
    path: '/approver/adhoc_transactions',
    label: 'Transactions',
    icon: <AppstoreOutlined />,
  }
];


// const checkNavList = [
//   {
//     path: '/admin/shipments',
//     label: 'Shipments',
//     icon: <GiftOutlined />,
//   },
//   {
//     path: '/admin/jobs',
//     label: 'Jobs',
//     icon: <AppstoreOutlined />,
//   },
//   {
//     path: '/admin/hubs',
//     label: 'Hubs',
//     icon: <AppstoreOutlined />,
//   },
//   {
//     path: '/admin/locations',
//     label: 'Locations',
//     icon: <AppstoreOutlined />,
//   },
//   {
//     path: '/admin/routes',
//     label: 'Routes',
//     icon: <AppstoreOutlined />,
//   },

//   {
//     path: '/admin/route-schedules',
//     label: 'Route Schedules',
//     icon: <AppstoreOutlined />,
//   },

// ];
const adminNavList = [
  {
    path: '/admin/adhoc_txns',
    label: 'Adhoc Txns',
    icon: <GiftOutlined />,
    // submenu: [
    //   {
    //     path: '/admin/adhoc_txns',
    //     label: 'Adhoc Txns',
    //     icon: <ClusterOutlined />,
    //   },
    //   {
    //     path: '/admin/jobs',
    //     label: 'Jobs',
    //     icon: <AppstoreOutlined />,
    //   }
    // ]
  },
  // {
  //   path: '/admin/hubs',
  //   label: 'Infra',
  //   icon: <InfoCircleOutlined />,
  //   submenu: [
  //     {
  //       path: '/admin/hubs',
  //       label: 'Hubs',
  //       icon: <ClusterOutlined />,
  //     },
  //     {
  //       path: '/admin/sections',
  //       label: 'Sections',
  //       icon: <BranchesOutlined />,
  //     },
  //     {
  //       path: '/admin/locations',
  //       label: 'Locations',
  //       icon: <AppstoreOutlined />,
  //     },
  //     {
  //       path: '/admin/pallets',
  //       label: 'Pallets',
  //       icon: <ShoppingCartOutlined />,
  //     },
  //   ]
  // },
  // {
  //   path: '/admin/routes',
  //   label: 'Routes',
  //   icon: <FullscreenOutlined />,
  //   submenu: [
  //     {
  //       path: '/admin/routes',
  //       label: 'Route Plan',
  //       icon: <ProfileOutlined />,
  //     },
  //     {
  //       path: '/admin/bags',
  //       label: 'Bags',
  //       icon: <ProfileOutlined />,
  //     },
  //     {
  //       path: '/admin/route-schedules',
  //       label: 'Schedule',
  //       icon: <ScheduleOutlined />,
  //     },
  //     {
  //       path: '/admin/route-roosters',
  //       label: 'Rooster',
  //       icon: <DragOutlined rotate={45} />,
  //     }
  //   ]
  // },
  // {
  //   path: '/admin/teams',
  //   label: 'Teams',
  //   icon: <FullscreenOutlined />,
  //   submenu: [
  //     {
  //       path: '/admin/teams',
  //       label: 'Teams',
  //       icon: <ProfileOutlined />,
  //     }
  //   ]
  // },
];

const services = [
  // Admin Services
  {
    code: 'admin-adhoc-txns',
    name: 'Adhoc Txns',
    link: '/admin/adhoc_txns',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-jobs',
    name: 'Jobs',
    link: '/admin/jobs',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  // Infra
  {
    code: 'admin-hubs',
    name: 'Hubs',
    link: '/admin/hubs',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-sections',
    name: 'Sections',
    link: '/admin/sections',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-locations',
    name: 'Locations',
    link: '/admin/locations',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-pallets',
    name: 'Pallets',
    link: '/admin/pallets',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  // Routes
  {
    code: 'admin-routes',
    name: 'Route Plan',
    link: '/admin/routes',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-bags',
    name: 'Bags',
    link: '/admin/bags',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-route-schedules',
    name: 'Schedule',
    link: '/admin/route-schedules',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  {
    code: 'admin-route-roosters',
    name: 'Rooster',
    link: '/admin/route-roosters',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  }, {
    code: 'admin-teams',
    name: 'Teams',
    link: '/admin/teams',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: adminNavList,
  },
  // Merchant Services
  {
    code: 'approver-orders',
    name: 'Transactions',
    link: '/approver/adhoc_transactions',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: approverNavList,
  },
  {
    code: 'merchant-order-create',
    name: 'Create Order',
    link: '/merchant/order/create',
    icon: (
      <IdcardTwoTone
        twoToneColor="#06b7ab"
        style={{ fontSize: '50px', display: 'block' }}
      />
    ),
    nav: approverNavList,
  },
];

export default services;
