
import axios from './custom-axios';
import { API_URI } from '../config';
const errorHandler = async (error) => {
    if (error.response && error.response.status == 403) {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('authType');
        window.location.reload();
    }


};
export default errorHandler;