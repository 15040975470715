import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Button, Form, Divider } from 'antd';
import qs from 'querystring';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates';
import { EditableTable } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import errorHandler from '../../../../utils/errorHandler';

function ApproverAdhocTxnList() {
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [shipments, setOrders] = useState([]);
  const statusValues = ["CREATED", "COMPLETED"];
  const [currentPage, setCurrentPage] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [bodyData, setBodyData] = useState({ 'page': currentPage, 'limit': currentLimit, 'date': '' });

  const getOrders = useCallback((params = bodyData) => {
    let apiUrl = `${API_URI}/approver/v1/adhoc_transactions`;
    if (params) {
      const { variables } = params;
      apiUrl += '?' + qs.stringify(variables);
    }
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    axios.get(apiUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
      .then((response) => {
        if ("data" in response && "data" in response.data) {
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setOrders(response.data.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }, []);

  useEffect(() => {
  }, [getOrders]);


  useEffect(() => {
    let authorization = localStorage.getItem('jwtToken');
    function getTxns() {
      let data = '',
        apiUrl = `${API_URI}/approver/v1/adhoc_transactions`;
      setFetching(true);
      axios.get(apiUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
        .then((response) => {
          if ("data" in response && "data" in response.data) {
            setCurrentPage(response.data.data.page);
            setTotal(response.data.data.total_count);
            setOrders(response.data.data.data);
            setFetching(false);
          }
        }).catch(async (error) => {
          await errorHandler(error);
          setFetching(false);
          sendNotification('Failed to fetch Txns', '', 'error');
        })
    }
    getTxns();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {
    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    getOrders(data);
    setBodyData(data);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };

  const handleApprove = async (tableData) => {
    let apiUrl = `${API_URI}/approver/v1/send_mail`;
    let authorization = localStorage.getItem('jwtToken');
    axios.post(apiUrl, selected['keys'], { headers: { 'Content-type': 'application/json; charset=utf-8', 'Authorization': authorization } })
      .then((response) => {
        getOrders();
        sendNotification("Txn processed", "", "success");
      }).catch(err => {
        sendNotification("Txn not processed", "", "error");
      });
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '100px',
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Transaction Ref num',
      dataIndex: 'reference_number',
      key: 'reference_number',
      width: '100px',
      sorter: (a, b) => parseInt(a.reference_number) - parseInt(b.reference_number),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WareHouse',
      dataIndex: 'warehouse',
      key: 'warehouse',
      width: '100px',
    },
    {
      title: 'Reference Id',
      dataIndex: 'request_id',
      key: 'request_id',
      width: '100px',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '100px',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '100px',
    },
    {
      title: 'File',
      dataIndex: 'file_name',
      key: 'file',
      width: '100px',
      render: (text, record) => (
        <a href={record.file_name} target="_blank">{record.reference_number} </a>
      ),
    },
    {
      title: 'New File',
      dataIndex: 'new_file_name',
      key: 'new_file_name',
      width: '100px',
      render: (text, record) => {
        if (record.new_file_name != null) {
          return (
            <a href={record.new_file_name} target="_blank">{"New-" + record.reference_number} </a>
          )
        }
      },
    },
    {
      title: 'barcode',
      key: 'file',
      width: '200px',
      dataIndex: 'barcode_image',
      render: (text, record) => <img style={{ width: 200 + "px", height: 100 + "px" }} src={record.barcode_image} />
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '100px',
    },
  ];

  const inputs = [
    {
      name: 'date',
      label: 'Date',
      type: 'datepicker',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
    },
    {
      name: 'status',
      label: 'Status',
      rules:
        [
          {
            required: false,
            message: 'Section Type is required'
          }
        ],
      options: statusValues,
      hasFeedback: true,
      type: 'select',
      col: true,
    }
  ];

  const onApprove = async () => {
    if (selected['keys'].length == 0) {
      sendNotification("please select to approve", "", "error")
    }
    handleApprove();
  };

  return (
    <Admin sidenav="approver-orders">
      <PageHeader
        title="Adhoc Transactions"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={onApprove}
              style={{ marginLeft: '2%' }}
            >
              Approve
            </Button>
          </Col>
        </Row>
        <Divider />
      </PageHeader>

      <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
        <EditableTable
          rowKey="id"
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={shipments}
          loading={isFetching}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: currentLimit,
            total: total,
            onChange: handlePageChange,
            current: currentPage
          }}
          size="small"
          scroll={{ x: '1600px' }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selected.keys,
            onChange: handleSelectRecord,
          }}
        />
      </div>
    </Admin>
  );
}

export default ApproverAdhocTxnList;

