import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu } from 'antd';

const evalPattern = (pattern, params) => {
  const parts = pattern.split('/');
  return parts.map((item) => params[item.slice(1)] || item).join('/');
};

function SideNav({ sections }) {
  const match = useRouteMatch();

  const { path: navKey = '', items: navItems = [] } =
    sections.find((item) => {
      var check = false;
      if (item.submenu) {
        item.submenu.find((subnav) => {
          check = match.path.includes(subnav.path);
          if (check) { item.path = subnav.path; }
          return match.path.includes(subnav.path);
        });
        return check;
      }
      else { return match.path.includes(item.path); }
    }) || {};

  const reverse = [...navItems];
  const { path: subNavKey = '' } =
    reverse.reverse().find((item) => match.path.includes(item.path)) || {};
  
  return (
    <Menu
      theme="light"
      selectedKeys={[subNavKey || navKey]}
      defaultOpenKeys={[subNavKey || navKey]} //Changed 'and' operation to 'or' for dropdown open when item in it is selected.
      mode="inline"
    >
      {sections.map(({ items, ...nav }) => {
        if (!items) {
          if (nav.submenu) {
            return (
              <Menu.SubMenu
                key={nav.path}
                icon={nav.icon}
                title={nav.label}
                disabled={Boolean(nav.disabled)}
              >
                {nav.submenu.map((subNav) => (
                  <Menu.Item key={subNav.path} disabled={Boolean(subNav.disabled)} icon={subNav.icon}>
                    <Link to={evalPattern(subNav.path, match.params)}>
                      <>
                        {subNav.label}
                      </>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }
          return (
            <Menu.Item key={nav.path} disabled={Boolean(nav.disabled)} icon={nav.icon}>
              <Link to={evalPattern(nav.path, match.params)}>
                <>
                  {/* {nav.icon} */}
                  {nav.label}
                </>
              </Link>
            </Menu.Item>
          );
        }
        return (
          <Menu.SubMenu
            key={nav.path}
            icon={nav.icon}
            title={nav.label}
          >
            {items.map((subNav) => (
              <Menu.Item key={subNav.path} disabled={Boolean(subNav.disabled)} icon={subNav.icon}>
                <Link to={evalPattern(subNav.path, match.params)}>
                  <>{subNav.label}
                  </>
                </Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        );
      })}
    </Menu>
  );
}

export default SideNav;
