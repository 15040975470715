import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { APP_STAGE } from '../../../config';
import services from '../../../services';

const getServiceInfo = (serviceCode) => {
  return services.find((service) => service.code === serviceCode);
};

function HeaderService({ serviceObj, serviceCode }) {
  const service = serviceObj || getServiceInfo(serviceCode);
  return (
    <div style={{ float: 'left', marginLeft: '1%' }}>
      {APP_STAGE !== 'prd' && (
        <Typography.Text style={{ color: '#06b7ab' }} code>
          {`_${APP_STAGE.toLocaleLowerCase()}:`}
        </Typography.Text>
      )}
      {service && <Link to={service.link}>{service.name}</Link>}
    </div>
  );
}

export default HeaderService;
