import React,{useState,useEffect} from 'react';
import { Layout } from 'antd';
import { Logo, HeaderService, HeaderMenu } from '../../atoms';
import { SideNav } from '../../molecules';
import services from '../../../services';

const { Header, Sider, Content, Footer } = Layout;

const getServiceInfo = (serviceCode) => {
  return services.find((service) => service.code === serviceCode);
};

function Merchant({ sidenav = 'iam', children }) {
  const service = getServiceInfo(sidenav);
  // const [collapsed, onNavCollapse] = useState(false);
  const [width,setWidth ] = useState(window.innerWidth);
  const [collapsed, onNavCollapse] = useState(width<=500 ? true:false);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    if(window.innerWidth <=500){
      onNavCollapse(true);
    }
    else{
      onNavCollapse(false);
    }
  };
  useEffect(()=>{
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  },[])

  return (
    <Layout id="admin-template">
      <Header style={{flex:1,flexDirection:'column'}}>
        <Logo />
        <HeaderService serviceObj={service} />
        <HeaderMenu width={width}/>
      </Header>
      <Layout>
        <Sider theme="light" collapsible collapsed={collapsed} onCollapse={() => onNavCollapse(!collapsed)}>
          <SideNav sections={service.nav} />
        </Sider>
        <Layout>
          <Content className="admin-content">{children}</Content>
          <Footer className="app-footer">Logistics Merchant © GrowSari 2021</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Merchant;
