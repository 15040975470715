import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Card, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { SimpleForm } from '../../../molecules';
import AddAdhocTxnItem from './addAdhocTxnItem';
import moment from "moment";
import axios from '../../../../utils/custom-axios';
import sendNotification from '../../../../utils/sendNotification';
import { API_URI } from '../../../../config';

function AddTxn({ onComplete }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isItemModalVisible, setIsItemModalVisible] = useState(false);
    const [types, setTypeList] = useState([]);
    const [warehouseList, setWareHouseList] = useState([]);
    const [warehouseProductList, setWareHouseProductList] = useState([]);
    const [warehouseId, setWareHouseId] = useState(-1);
    const [ticketNumber, setTicketNumber] = useState(-1);
    const [type, setType] = useState("");

    const getTxntypes = useCallback((params) => {
        let warehouseUrl = `${API_URI}/admin/v1/transaction_types`;
        let authorization = localStorage.getItem("jwtToken");
        axios.get(warehouseUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
            .then((response) => {
                if ("data" in response && "data" in response.data) {
                    var txnsRes = response.data.data.data;
                    var txnTypes = [];
                    for (var i = 0; i < txnsRes.length; i++) {
                        var txnObj = {
                            'id': txnsRes[i].code,
                            "value": txnsRes[i].code,
                            "label": txnsRes[i].name
                        }
                        txnTypes.push(txnObj);
                    }
                    setTypeList(txnTypes);
                }
            }).catch((err) => {
            });
    }, []);

    const getWarehouses = useCallback((params) => {
        let warehouseUrl = `${API_URI}/admin/v1/warehouses`;
        let authorization = localStorage.getItem("jwtToken");
        axios.get(warehouseUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
            .then((response) => {
                if ("data" in response && "data" in response.data) {
                    var warehouseRes = response.data.data;
                    var warehouseIds = [];
                    for (var i = 0; i < warehouseRes.length; i++) {
                        var warehouseId = {
                            'id': warehouseRes[i].id,
                            "value": warehouseRes[i].id,
                            "label": warehouseRes[i].name
                        }
                        warehouseIds.push(warehouseId);
                    }
                    setWareHouseList(warehouseIds);
                }
            }).catch((err) => {
            });
    }, []);

    const getWareHouseProducts = async (warehouseId) => {
        return new Promise(async function (resolve, reject) {
            var response;
            let apiUrl = `${API_URI}/admin/v1/warehouse_products?warehouse_id=` + warehouseId;
            let authorization = localStorage.getItem("jwtToken");
            var warehouseProductIds = [];
            response = await axios.get(apiUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
            if ("data" in response && "data" in response.data) {
                var res = response.data.data;
                for (var i = 0; i < res.length; i++) {
                    warehouseProductIds.push(res[i]);
                }
            }
            resolve(warehouseProductIds);
        });
    }

    useEffect(() => {
        getWarehouses();
    }, [getWarehouses]);

    useEffect(() => {
        getTxntypes();
    }, [getTxntypes])

    const inputs = [
        {
            name: 'type',
            label: 'Type',
            rules:
                [
                    {
                        required: true,
                        message: 'Type is required'
                    },
                ],
            options: types,
            hasFeedback: true,
            type: 'select',
        },
        {
            name: 'warehouse_id',
            label: 'Warehouse',
            rules:
                [
                    {
                        required: true,
                        message: 'Warehouse is required'
                    }
                ],
            options: warehouseList,
            type: 'select',
            hasFeedback: true,
        },
        {
            name: 'ticket_number',
            label: 'Ticket Number',
            rules:
                [
                    {
                        required: true,
                        message: 'Ticket is required'
                    }
                ],
            hasFeedback: true,
        },
    ];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };
    const handleComplete = () => {
        onComplete(true);
        debugger;
    }
    const createFile = (locationValue) => {
        onComplete(locationValue);
    };

    const processFormData = async (locationValue) => {
        setType(locationValue['type']);
        setWareHouseId(locationValue['warehouse_id']);
        setTicketNumber(locationValue['ticket_number']);
        var warehouseProductList2 = await getWareHouseProducts(locationValue['warehouse_id']);
        setWareHouseProductList(warehouseProductList => warehouseProductList2);
        setIsModalVisible(false);
        setIsItemModalVisible(true);
    };

    const handleSubmit = async () => {
        const { meta, ...values } = await form.validateFields();
        var addValues = {};
        for (var value in values) {
            addValues[value] = values[value];
            if (values[value] instanceof moment) {
                addValues[value] = values[value].toDate();
            }
        }
        form.resetFields();
        processFormData(addValues);

    };
    return (
        <div>
            <Modal title="Add Txn"
                visible={isModalVisible}
                onOk={handleOk}
                closable={false}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <Card>
                        <SimpleForm form={form} layout="vertical" inputs={inputs} />
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit}
                            icon={<SaveOutlined />}
                            style={{ marginTop: 30 }}
                        >
                            Save
                        </Button>
                    </Card>

                </div>
            </Modal>

            <Button type="primary" onClick={showModal}>
                Add Txn
            </Button>
            <AddAdhocTxnItem isVisible={isItemModalVisible} warehouseId={warehouseId} type={type} ticketNumber={ticketNumber} onComplete={createFile} warehouseProductList={warehouseProductList} ></AddAdhocTxnItem>
        </div>)

}

export default AddTxn;

