import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Button, Form, Divider, Spin } from 'antd';
import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates';
import { EditableTable, SimpleForm } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import AddTxn from './addAdhocTxn';
import errorHandler from '../../../../utils/errorHandler';
import BulkUpload from './bulkUpload';

function AdhocTxnList() {
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [orders, setOrders] = useState([]);
  const statusValues = ["CREATED", "COMPLETED"];
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [bodyData, setBodyData] = useState({ 'page': currentPage, 'limit': currentLimit, 'date': "" });

  const getOrders = useCallback((params = bodyData) => {
    let apiUrl = `${API_URI}/admin/v1/adhoc_transactions`;
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    axios.get(apiUrl, { params: params, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        if ("data" in response && "data" in response.data) {
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setOrders(response.data.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }, []);

  useEffect(() => {
  }, [getOrders]);


  useEffect(() => {
    let authorization = localStorage.getItem('jwtToken');
    function getTxns() {
      let apiUrl = `${API_URI}/admin/v1/adhoc_transactions`;
      setFetching(true);
      axios.get(apiUrl, { headers: { "Authorization": authorization, 'Content-type': 'application/json;  charset=utf-8' } })
        .then((response) => {
          if ("data" in response && "data" in response.data) {
            setCurrentPage(response.data.data.page);
            setTotal(response.data.data.total_count);
            setOrders(response.data.data.data);
            setFetching(false);
          }
        }).catch(async (error) => {
          await errorHandler(error);
          setFetching(false);
          sendNotification('Failed to fetch Txns', '', 'error');
        })
    }
    getTxns();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {
    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    getOrders(data);
    setBodyData(data);
    setCurrentPage(page);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };

  const reUpload = (record) => {
    let apiUrl = `${API_URI}/admin/v1/send_mail?re_upload=1&id=` + record.id;
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        if ("data" in response && "data" in response.data) {
          setFetching(false);
          sendNotification('Reuploaded Txn', '', 'success');
          getOrders();
        }
      })
      .catch(async (error) => {
        setFetching(false);
        await errorHandler(error);
        sendNotification('Failed to Reupload Delete Txn', '', 'error');
      })
  }

  const deleteRecord = (record) => {
    let apiUrl = `${API_URI}/admin/v1/delete_txn?id=` + record.id;
    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {
        if ("data" in response && "data" in response.data) {
          setFetching(false);
          sendNotification('Deleted Txn', '', 'success');
          getOrders();
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to Delete Txn', '', 'error');
      })
  }

  const handleUpload = async (tableData) => {
    let authorization = localStorage.getItem("jwtToken");
    let apiUrl = `${API_URI}/admin/v1/create_txn`;
    var createLocationData = tableData;
    axios.post(apiUrl, createLocationData, { headers: { 'Authorization': authorization, 'Content-type': 'application/json; charset=utf-8', } })
      .then((response) => {
        getOrders();
        sendNotification("Txn processed", "", "success");
      }).catch(err => {
        sendNotification("Txn not processed", "", "error");
      });
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '100px',
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Transaction Ref num',
      dataIndex: 'reference_number',
      key: 'reference_number',
      width: '100px',
      sorter: (a, b) => (a.reference_number) - (b.reference_number),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'WareHouse',
      dataIndex: 'warehouse',
      key: 'warehouse',
      width: '100px',
    }, {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '100px',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '100px',
    },
    {
      title: 'File',
      dataIndex: 'file_name',
      key: 'file',
      width: '100px',
      render: (text, record) => (
        <a href={record.file_name} target="_blank">{record.reference_number} </a>
      ),
    },
    {
      title: 'New File',
      dataIndex: 'new_file_name',
      key: 'new_file_name',
      width: '100px',
      render: (text, record) => {
        if (record.new_file_name != null) {
          return (
            <a href={record.new_file_name} target="_blank">{"New-" + record.reference_number} </a>
          )
        }
      },
    },
    {
      title: 'barcode',
      key: 'file',
      width: '200px',
      dataIndex: 'barcode_image',
      render: (text, record) => <img style={{ width: 200 + "px", height: 100 + "px" }} src={record.barcode_image} />
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '100px',
      render: (text, record) => {
        if (record['status'] == "CREATED") {
          return <Button type="danger" onClick={() => deleteRecord(record)}>Delete</Button>
        }
        if (record['status'] == "COMPLETED") {
          return <Button type="primary" onClick={() => reUpload(record)}>Reupload</Button>
        }
      },
    },
  ];

  const inputs = [
    {
      name: 'date',
      label: 'Date',
      type: 'datepicker',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
    },
    {
      name: 'status',
      label: 'Status',
      rules:
        [
          {
            required: false,
            message: 'Section Type is required'
          }
        ],
      options: statusValues,
      hasFeedback: true,
      type: 'select',
      col: true,
    }
  ];

  const onFormFinish = async () => {
    const { meta, ...values } = await form.validateFields();
    var addValues = {};
    for (var value in values) {
      if (values[value] !== '' && values[value] !== null && values[value] !== undefined) {
        addValues[value] = values[value];
        if (values[value] instanceof moment) {
          addValues[value] = values[value].format("YYYY-MM-DD");
        }
      }
    }
    addValues['page'] = currentPage;
    addValues['limit'] = currentLimit;
    setBodyData(addValues);
    getOrders(addValues);
  };

  return (
    <Admin sidenav="admin-adhoc-txns">
      <PageHeader
        title="Adhoc Transactions"
        extra={[
          <AddTxn onComplete={handleUpload} key='addHub' />,
          <BulkUpload></BulkUpload>
        ]}
      >
        <Row gutter={16}>
          <Col span={24}>
            <SimpleForm form={form} layout="verticall" inputs={inputs} row={true}
            />
            <Button
              type="primary"
              htmlType="submit"
              onClick={onFormFinish}
              style={{ marginLeft: '2%' }}
            >
              Filter Transactions
            </Button>
          </Col>
        </Row>
        <Divider />
      </PageHeader>
      {!isFetching &&
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          <EditableTable
            rowKey="id"
            rowClassName={() => 'editable-row'}
            columns={columns}
            dataSource={orders}
            loading={isFetching}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: currentLimit,
              total: total,
              onChange: handlePageChange,
              current: currentPage
            }}
            size="small"
            scroll={{ x: '1600px' }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selected.keys,
              onChange: handleSelectRecord,
            }}
          />
        </div>}
      {isFetching && <div style={{
        flex: 1, width: "100%",
        height: 500 + "px",
        backgroundColor: "#f0f2f5",
        textAlign: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        padding: 150 + "px"
      }}><Spin></Spin></div>}
    </Admin>
  );
}

export default AdhocTxnList;
